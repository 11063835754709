/* 
Global
*/

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  padding: 0;
  margin: 0 0;
}

a {
  color: inherit;
  text-decoration: inherit;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

code {
  font-size: 15px;
}

/* 
Authenticator
*/

[data-amplify-qrcode="true"] {
  margin: auto !important
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* 
Nav Bar 
*/

#navBar {
  background: linear-gradient(90deg, var(--amplify-colors-brand-primary-80) 0%, var(--amplify-colors-brand-secondary-100) 50%);
}

#navBar .amplify-button {
  box-shadow: none;
  outline: 0;
  border: none;
}

#navBar .amplify-togglebutton--pressed h6 {
  font-weight: var(--amplify-font-weight-bold) !important
}

#navBar .amplify-togglebutton--pressed {
  border-bottom: 4px solid var(--amplify-colors-brand-secondary-20) !important;
}


#navBar .amplify-icon {
  color: white;
}

#navBar .amplify-menu-trigger {
  color: var(--amplify-colors-white);
  margin-right: var(--amplify-space-medium);
}

/* 
Modal 
*/

.overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.ReactModal__Overlay {
  margin-top: 48px;
}

.ReactModal__Content {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px,
    rgba(0, 0, 0, 0.23) 0px 3px 30px, ;
  max-height: 80vh;
}

/* 
Tables 
*/

.amplify-table__th {
  color: var(--amplify-colors-brand-primary-100) !important;
}

.amplify-table__td {
  --amplify-components-table-data-color: var(--amplify-colors-font-secondary-40)
}

.amplify-table__row:not(:first-child) .amplify-table__th {
  border-top: none;
}

.amplify-table__row:not(:last-child) .amplify-table__th {
  border-bottom: none;
}

/* 
Expander 
*/

.amplify-expander,
.amplify-expander__trigger,
.amplify-expander__trigger:focus {
  color: var(--amplify-colors-brand-primary-100) !important;
}


input::file-selector-button {
  border: thin solid grey;
  border-radius: 3px;
}

input::file-selector-button:hover {
  background-color: lightgray;
  border: thin solid grey;
  border-radius: 3px;
}


/* 
Loader 
*/
.amplify-loader {
  animation-name: fadeIn !important;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: 1;
  animation-delay: 2s !important;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


/* 
Badge Tooltip Styling
Ref: https://www.w3schools.com/css/css_tooltip.asp 
*/


/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: default
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: rgb(70, 69, 69);
  color: #fff;
  text-align: center;
  padding: 15px;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  text-align: left;
}

.tooltip .tooltiptext {
  top: -20px;
  left: 150%;
  width: 400px;
}

.tooltip .tooltiptext::after {
  content: " ";
  position: absolute;
  top: 50%;
  right: 100%; /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}

.amplify-tabs-item {
  -webkit-touch-callout: none;
  /* Safari */
  -webkit-user-select: none;
  /* Chrome */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

#deployedTerminalServers td {
  cursor: default !important;
}

table.sortable th {
  cursor: pointer;
  user-select: none;
}

.sortable thead th:not(.no-sort)[aria-sort="descending"]::after {
  color: inherit;
  content: "▾";
}

.sortable thead th:not(.no-sort)[aria-sort="ascending"]::after {
  color: inherit;
  content: "▴";
}

.amplify-button.modal-close-button {
  position:absolute;
  right:1em;
  top:1em;
  font-size:.5em;
  padding:.5em;
}

th.hiddenColumnByDefault, td.hiddenColumnByDefault {
  display: none;
}

.terminalServerInfoTooltipIcon {
  cursor: pointer;
}

.terminalServerInfoTooltip {
  display: none;
  font-size: .8em;
  color: gray;
}